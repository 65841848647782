import Persona, { PersonaName } from "./Persona";
import React, { useEffect, useState } from "react";

const personas: PersonaName[] = [
  "default",
  "zen",
  "comedian",
  "intern",
  "poet",
];

const PersonaSelector: React.FC<{
  onSelect: (persona: PersonaName) => void;
}> = ({ onSelect }) => {
  const [selectedPersona, setSelectedPersona] = useState<PersonaName | null>(
    null
  );
  const handlePersonaClick = (persona: PersonaName) => {
    setSelectedPersona(persona);
    onSelect(persona);
  };

  useEffect(() => {
    const savedPersona = localStorage.getItem("selectedPersona");
    setSelectedPersona((savedPersona as PersonaName) ?? "default");
  }, []);

  useEffect(() => {
    if (selectedPersona) {
      localStorage.setItem("selectedPersona", selectedPersona);
    }
  }, [selectedPersona]);

  return (
    <div className="persona-selector-grid">
      {personas.map((persona) => (
        <div
          key={persona}
          className={`persona-selector-item ${
            selectedPersona === persona ? "selected" : ""
          }`}
          onClick={() => handlePersonaClick(persona)}
        >
          <Persona type={persona} />
        </div>
      ))}
    </div>
  );
};

export default PersonaSelector;
