import "./App.css";

import { auth, onAuthStateChanged, signInAnonymously } from "./firebase";
import { useEffect, useState } from "react";

import Header from "./Header";
import MeshAnimation from "./MeshAnimation";
import { PersonaName } from "./persona/Persona";
import PersonaSelector from "./persona/PersonaSelector";
import QueryInput from "./Chat";
import { User } from "firebase/auth";
import { callClearMessages } from "./api";

function App() {
  const [user, setUser] = useState<User | null>(null);

  const [selectedPersona, setSelectedPersona] = useState<PersonaName | null>(
    null
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    signInAnonymously(auth);
  }, []);

  return (
    <div className="App">
      <Header />
      <div className="persona-label">Choose your virtual assistant:</div>

      <div className="persona-selector-wrapper">
        <PersonaSelector
          onSelect={async (selectedPersona) => {
            setSelectedPersona(selectedPersona);
            await callClearMessages();
          }}
        />
      </div>
      {user && (
        <div className="screen-overlay-container">
          <QueryInput uid={user?.uid} persona={selectedPersona ?? "default"} />
        </div>
      )}

      <MeshAnimation />
    </div>
  );
}

export default App;
