export type PersonaName = "default" | "zen" | "comedian" | "intern" | "poet";

const PersonaTypes: Record<PersonaName, { name: string; tagline: string }> = {
  default: {
    name: "Wattson",
    tagline: "The digital mind at your service.",
  },
  comedian: {
    name: "Chuckles",
    tagline: "Bringing humor to your every task.",
  },
  intern: {
    name: "Teddy",
    tagline: "Barely here, but still helping out.",
  },
  poet: {
    name: "Quilliam",
    tagline: "Penning verses in digital ink.",
  },
  zen: {
    name: "Zenon",
    tagline: "Your guide to mindfulness and serenity.",
  },
};

type PersonaProps = {
  type: PersonaName;
};

const Persona: React.FC<PersonaProps> = ({ type }) => {
  const persona = PersonaTypes[type];

  return (
    <div className="persona-container">
      <img
        src={`https://wattl.digital/wp-content/uploads/2024/08/${type}.png`}
        width={60}
        alt={`${persona.name} avatar`}
        className="persona-image"
      />
      <p className="persona-name">{persona.name}</p>
      <p className="persona-tagline">{persona.tagline}</p>
    </div>
  );
};

export default Persona;
