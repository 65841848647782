const Header = () => (
  <div className="header-container">
    <div className="header-left">
      <img
        src="https://wattl.digital/wp-content/uploads/2024/08/logo-word.png"
        alt="Logo"
        className="header-logo"
      />
    </div>
    <div className="header-right">
      <div className="header-item">
        <a href="/index.php/home">Website</a>
      </div>
      <div className="header-item">
        <a href="/index.php/blog">Blog</a>
      </div>
    </div>
  </div>
);

export default Header;
